* {
    padding: 0;
    margin: 0;
    /* overflow: hidden; */
}
.ant-btn {
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center
}

.ant-tag {
    border-radius: 0;
}
.ant-popconfirm-buttons {
    display: flex;
}
.ant-modal-confirm-btns{
    display: flex;
}