.header {
    padding: 0 24px;
    background-color: #fff;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    border-bottom: 2px solid #e3e5e7;
    .left {
        float: left;
    }

    .right {
        float: right
    }
}

.main {
    min-height: 600px;
}

.footer {
    text-align: center;
    background-color: #fff;
    border-top: 1px solid #e8e8e8;
}