.container {
    padding: 24px 0;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    // margin-top:20px;
}

.left {
    width: 120px;
    margin-top:20px;
    button {
        height: 35px;
    }
}

.right {
    flex: 1;
    margin-left: 60px;
}