.container {
    width: 200px;
    margin:12px auto;
    line-height: 1;
    text-align: center;
    margin-right: 800px;
    
    h1 {
        font-size: 24px;
        color: #262626;
        text-align: center;
    }
}