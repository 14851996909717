.container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 600px;
    background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
    .info {
        text-align: center;
    }
}
.button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
}