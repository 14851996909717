.container {
    display: flex;
    min-height: 600px;
    
    .main {
        margin: 20px 0 0 100px;
        border: 1px solid;
        width: 80%;
        padding:15px;
        h2 {
            border-bottom: 1px solid #e3e5e7;
        }
        .place {
            width: 200px;
            height: 200px;
            text-align: center;
            font-size: 40px;
            line-height: 200px;
            border: 1px solid #ddd;
            color: #aaa;
            cursor: pointer;
            display: inline-block;
            vertical-align: top;
        }
    }


}

